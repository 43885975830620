<template>
  <div>
    <!-- PPTX only -->
    <q-uploader
      v-show="false"
      ref="pptx_upload"
      label="PPTX"
      url="/api/upload"
      auto-upload
      :headers="getHeaders"
      :multiple="false"
      accept=".pptx"
      @uploaded="PPTXUploadDone"
    />

    <!-- thumbnail only -->
    <q-uploader
      v-show="false"
      ref="iconic_upload"
      :url="getUploadUrl"
      auto-upload
      :headers="thumbHeaders"
      :multiple="false"
      accept="image/*"
      @uploaded="thumbUploadDone"
    />

    <!-- the editor -->
    <q-dialog
      v-model="showDialog"
      @hide="close"
      maximized
      transition-show="slide-down"
      transition-hide="slide-up"
    >
      <q-card style="max-height: 80vh; max-width: 80vw">
        <q-bar>
          <span class="form-title">{{ mode }} a Collection</span>
          <q-space />
          <q-icon v-close-popup name="far fa-times" />
        </q-bar>

        <q-scroll-area style="max-height: 73vh">
          <div class="q-pa-md">
            <div v-if="record.thumb">
              <q-img :src="record.thumb" class="iconic-image" /><br />
              <q-btn
                icon="fas fa-trash"
                color="primary"
                label="Delete thumb"
                @click="deleteThumb(record.uuid)"
              />
            </div>

            <div v-else>
              <div id="dummy" class="iconic-image flex justify-center">
                <p class="center">No iconic image</p>
              </div>
              <q-btn
                label="Upload image"
                icon="fas fa-upload"
                color="primary"
                @click="$refs.iconic_upload.pickFiles()"
              />
            </div>
          </div>

          <q-card-section>
            <q-input hint="name" filled type="text" v-model="record.name" />
            <q-input hint="slug" filled type="text" v-model="record.slug" />
            <q-input
              hint="subtitle"
              filled
              type="textarea"
              autogrow
              v-model="record.subtitle"
            />
            <q-input
              hint="description"
              filled
              type="textarea"
              autogrow
              v-model="record.description"
            />
            <q-input
              hint="acknowledgements"
              filled
              type="textarea"
              autogrow
              v-model="record.acknowledgements"
            />
            <q-select
              hint="status"
              filled
              v-model="record.status"
              :options="['editor', 'uploaded', 'public']"
            />

            l: {{ record.license }}

            <hi-license
              hint="Global license"
              v-model="record.license"
              style="max-width: 400px"
            />
          </q-card-section>

          <q-card-section>
            <q-markup-table separator="horizontal">
              <tr :key="offset" v-for="(i, offset) in record.items">
                <td width="40%">
                  <q-img :src="i.url" contain />
                </td>
                <td width="40%">
                  <q-input hint="title" type="text" v-model="i.title" />
                  <q-input
                    hint="description"
                    filled
                    type="textarea"
                    autogrow
                    v-model="i.description"
                  />
                </td>

                <td width="20%">
                  <hi-license />
                  <!-- do we really need a partner select here?? -->
                  <!-- h-select type="partner"/-->

                  <br />
                  <p>
                    <q-btn
                      round
                      dense
                      color="warning"
                      icon="fas fa-trash"
                      @click="itemDelete(offset)"
                    />
                  </p>
                  <br />
                  <q-btn
                    round
                    dense
                    color="primary"
                    icon="fas fa-arrow-up"
                    @click="itemUp(offset)"
                  >
                    <q-tooltip>move item up</q-tooltip> </q-btn
                  >&nbsp;
                  <q-btn
                    alt="down"
                    round
                    dense
                    color="primary"
                    icon="fas fa-arrow-down"
                    @click="itemDown(offset)"
                  >
                    <q-tooltip>move item dowm</q-tooltip>
                  </q-btn>
                </td>
              </tr>
            </q-markup-table>
          </q-card-section>
        </q-scroll-area>

        <q-card-actions align="center">
          <q-btn label="Cancel" v-close-popup color="secondary" />
          <q-btn label="Save" @click="saveData" color="primary" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <div id="index">
      <contentHeader :title="title" @input="setFilter" />
      <q-markup-table wrap-cells dense style="max-width: 100%">
        <thead>
          <tr>
            <th>Created</th>
            <th>Name</th>
            <th>Items</th>
            <th>Is published</th>
            <th>License</th>
            <th>Partner</th>
            <th>Member</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr :key="k" v-for="(c, k) in filteredCollections">
            <td>
              {{ d(c.ts) }}
            </td>
            <td style="max-width: 200px">
              {{ c.name }}
            </td>
            <td>{{ c.itemcount }}</td>
            <td v-html="is_published(c.is_published)"></td>
            <td>
              {{ c.license ? c.license.code : "&mdash;" }}
            </td>
            <td>
              <span v-if="c.partner">{{ c.partner.name }}</span>
            </td>
            <td>
              <span v-if="c.member">{{ c.member.name }}</span>
            </td>
            <td align="right" valign="bottom">
              <q-btn
                type="a"
                :href="getLink(c)"
                target="_new"
                color="primary"
                flat
                round
                dense
                icon="fad fa-eye"
              />
              <q-btn
                v-show="false"
                @click="cEdit(c.uuid)"
                color="primary"
                flat
                round
                dense
                icon="fad fa-pencil"
              />
              <q-btn
                @click="cDelete(c.uuid)"
                color="warning"
                flat
                round
                dense
                icon="fad fa-trash"
              />
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </div>
  </div>
</template>

<script>
import contentHeader from "@/admin/components/contentHeader";
import HiLicense from "@/components/hiLicense";
import hSelect from "@/components/hSelect";

import { humanDate } from "../tic.js";

export default {
  name: "admin-collections",
  props: ["showAdd", "title"],
  components: {
    HiLicense,
    hSelect,
    contentHeader,
  },

  watch: {
    showAdd: {
      handler(val, oldVal, e) {
        console.log("showAdd watch", val);
        this.add(val);
      },
    },

    // TODO: FIX
    // we really only want to see user input triggered changes
    // but loading data also triggers this, effectively having "Save"
    // enabled most of the time, regardless user state.
    record: {
      handler(val, oldVal, e) {
        console.log("record change handler with: ", val, oldVal, e);
        this.isChanged = true;
      },
      deep: true,
    },
  },

  computed: {
    filteredCollections: function () {
      if (this.search) {
        return this.collections.filter((e) =>
          JSON.stringify(e).toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return this.collections;
    },
  },

  methods: {
    setFilter(search) {
      this.search = search;
    },

    is_published(val) {
      return val ? `<span class="checkmark">&check;</span>` : "&mdash;";
    },

    getUploadUrl() {
      return `/api/upload?${this.current}`;
    },

    getHeaders() {
      return [
        { name: "X-Session", value: this.$store.getters["user/session"] },
        { name: "X-Process", value: "createCollectionfromPPTX" },
      ];
    },

    thumbHeaders() {
      return [
        { name: "X-Session", value: this.$store.getters["user/session"] },
        { name: "X-Process", value: "thumbForCollection" },
      ];
    },

    add() {
      this.$refs.pptx_upload.reset();
      this.$refs.pptx_upload.pickFiles();
    },

    d(ts) {
      return humanDate(ts);
    },

    close() {
      this.$emit("hideAdd");
      this.showDialog = false;
    },

    thumbUploadDone(e) {
      const resp = JSON.parse(e.xhr.response);
      this.record.thumb = resp.url;
      this.$refs.iconic_upload.reset();
    },

    PPTXUploadDone(e) {
      this.$q.notify({
        message: "Upload completed",
        position: "center",
        color: "primary",
        timeout: 1000,
      });
      this.loadData();
    },

    // KEEP THIS FOR API CALL REFERENCE
    // partnerRemove(item) {
    //   console.log("remove: ", item)
    //   this.$API.post("/collection/removePartner", {
    //     item: item.uuid,
    //     partner: item.partner
    //   })
    // },

    // partnerChanged(e) {
    //   console.log("change partner: ", e)
    //   this.$API.post("/collection/changePartner", {
    //     item: e.uuid,
    //     partner: e.partner
    //   })
    // },

    // copyRemove(item) {
    //   this.$API.post("/collection/removeCopyright", {
    //     item: item.uuid,
    //     copyright: item.copyright
    //   })
    // },

    // copyChanged(e) {
    //   this.$API.post("/collection/changeCopyright", {
    //     item: e.uuid,
    //     copyright: e.copyright
    //   })
    // },

    itemUp(i) {
      let item = this.record.items[i];
      this.record.items.splice(i, 1);
      this.record.items.splice(i - 1, 0, item);
      for (i = 0; i < this.record.items.length; i++) {
        this.record.items[i].order = i + 1;
      }
    },

    itemDown(i) {
      let item = this.record.items[i];
      this.record.items.splice(i, 1);
      this.record.items.splice(i + 1, 0, item);
      // fix order
      for (i = 0; i < this.record.items.length; i++) {
        this.record.items[i].order = i + 1;
      }
    },

    itemDelete(i) {
      let item = this.record.items[i];
      this.record.items.splice(i, 1);
      // let api know
      this.$API.post("/collection/deleteItem", {
        collection: this.record.uuid,
        item: item.uuid,
      });
    },

    saveData() {
      //console.log("save data")
      this.$API
        .post("/collection/edit/" + this.current, this.record)
        .then((r) => {
          console.log("saveData resp: ", r);
          this.state = "index";
          this.loadData();
          // close the edit popup
          this.close();
          this.$q.notify({
            message: "Changes saved",
            position: "center",
            color: "primary",
          });
        });
    },

    loadData() {
      console.log("** LOAD DATA");
      let vm = this;
      this.$API
        .get("/admin/collections")
        .then((r) => {
          this.collections = r.data.data;
          this.state = "index";
          vm.$forceUpdate();
          //this.isChanged = false
        })
        .catch((e) => {
          console.log("LOAD DATA ERROR: ", e);
        });
    },

    getLink(c) {
      // get link within context of current server
      let me = document.location.origin.toString();
      return `${me}/historical-content/source-collections/${c.slug}`;
    },

    cEdit(uuid) {
      // this.dialogVisible = true
      this.mode = "edit";
      this.current = uuid;

      this.$API.get(`/collection/edit/${uuid}`).then((r) => {
        console.log("loaded collection ", uuid);
        this.record = r.data.data;
        this.isChanged = false;
        this.showDialog = true;
      });

      this.isChanged = false;
    },

    deleteThumb(uuid) {
      // DELETE A THUMB FROM A COLLECTION
      this.$q
        .dialog({
          title: "Warning",
          message: "This will delete the cover image. Continue?",
          cancel: true,
          persistent: true,
        })
        .onOk(() => {
          // TODO: sent session or user to validate on server side if delete is permitted
          this.isChanged = false;
          this.$API
            .delete("/admin/collectionThumb/", {
              data: { collection: uuid, user: "user" },
            })
            .then((r) => {
              this.record.thumb = null;
              this.loadData();
            });
        })
        .onCancel(() => {
          this.$q.notify({
            message: "Delete canceled",
            position: "center",
            color: "primary",
          });
        });
    },

    cDelete(e) {
      // DELETE A COLLECTION
      this.$q
        .dialog({
          title: "Warning",
          message: "This will permanently delete the collection. Continue?",
          cancel: true,
          persistent: true,
        })
        .onOk(() => {
          // TODO: sent session or user to validate on server side if delete is permitted
          this.isChanged = false;
          this.$API
            .delete("/admin/collection/", {
              data: { collection: e, user: "user" },
            })
            .then((r) => {
              this.loadData();
              //this.$q.notify({message: 'Delete complete', position: 'center', color:'primary'})
            });
        })
        .onCancel(() => {
          this.$q.notify({
            message: "Delete canceled",
            position: "center",
            color: "primary",
          });
        });
    },
  },

  mounted() {
    console.log("** MOUNT Collections.vue");
    this.loadData();
  },

  data() {
    return {
      search: "",
      mode: null,
      showDialog: false,
      isChanged: false,
      copyrightOptions: [],
      state: "index",
      current: null,
      record: {},
      collections: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.iconic-image {
  width: 200px;
  height: 200px;
  border: 2px lightgrey dotted;
}

td {
  vertical-align: top;
}

.center {
  display: flex;
  align-items: center;
}

tr:nth-child(even) {
  background-color: #eee;
}

th {
  text-align: left;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;
  color: grey;
}

.checkmark {
  color: green !important;
  font-size: 1.2em !important;
}
</style>
